<template>
  <div class="wrap">
    <!-- 顶部黑块 -->
    <div class="wrapTop">
      <!-- 视频内容 -->
      <div class="videoBox">
        <div class="wrapTopsCen">
          <div class="room-title">
            <div class="elp">
              {{ detail ? detail.name : "" }}
            </div>
            <div class="detail-ter flex-c">
              <div class="ter-list">
                <div class="terbox">
                  <el-image class="ter-img" :src="courDetail ? courDetail.tImg : ''" fit="cover"></el-image>
                </div>
                <span class="ter">讲师：{{ courDetail ? courDetail.terName : "" }}</span>
              </div>
            </div>
          </div>
          <!-- 中间视频 -->
          <div class="wrapTopsCenCen" :style="`background-image: url(${courDetail.pcimg})`">
            <div class="audio-Box">
              <audio id="myAudio" :currentTime="thisTIme" :src="detail.audioFile" :controls="false" loop />
              <i v-if="!ifplay" @click="play" class="icon-bofang iconfont"></i>
              <i v-if="ifplay" @click="pause" class="icon-bofang1 iconfont"></i>
              <div class="audio-content">
                <div class="au-title elp">{{ detail ? detail.name : "" }}</div>
                <div class="au-tips elp">{{ courDetail ? courDetail.name : "" }}</div>
                <!--  -->
                <el-slider
                  class="slider"
                  :max="alltime"
                  :show-tooltip="false"
                  v-model="thisTIme"
                  @change="timeChange"
                >
                </el-slider>
              </div>
            </div>
          </div>
        </div>
        <audio-inf-chat
          class="room"
          :playid="id"
          :id="courseId"
          :list="courDetail ? courDetail.audioChapters : null"
          :classId="classId"
          :IsPurchase="IsPurchase"
        />
      </div>
    </div>
    <!-- 选项栏 -->
    <div class="content">
      <div class="tabTwo m-t-24">
        <div class="tabTwo-text" @click="NavIndex = 1" :class="NavIndex == 1 ? 'active' : ''">
          课程答疑
          <div v-if="NavIndex == 1" class="on"></div>
        </div>
        <div class="tabTwo-text" @click="NavIndex = 2" :class="NavIndex == 2 ? 'active' : ''">
          课程评分
          <div v-if="NavIndex == 2" class="on"></div>
        </div>
      </div>
      <!-- 课程答疑 -->
      <answer-view
        :type="1"
        :IsPurchase="IsPurchase"
        :status="3"
        :courseId="courseId"
        :chapterId="id"
        v-if="NavIndex == 1"
      />
      <!-- 课程评分 -->
      <score-evaluation
        v-if="NavIndex == 2"
        :Belonging="2"
        :chapterId="groupId"
        :sectionId="id"
        :type="7"
        :typeId="courseId"
        :typeNmae="detail.title"
        :IsPurchase="IsPurchase"
      />
    </div>
  </div>
</template>
<script>
import { getLbClassCourse } from "@/api/mykecheng";
import scoreEvaluation from "@/components/Know/course/scoreEvaluation.vue";
import answerView from "@/components/Know/course/answerView.vue";
import AudioInfChat from "../../../components/Know/course/audioInfChat.vue";
import { Know } from "@/api/know";
import { getInfo, getToken } from "@/api/cookies";
let know = new Know();
export default {
  components: { scoreEvaluation, answerView, AudioInfChat },
  data() {
    return {
      userInfo: {},
      // 课程详情信息
      courDetail: {},
      // 课程节详情信息
      detail: {
        courseId: "",
      },
      uservideoConfig: {},
      NavIndex: 1,
      IsPurchase: false,
      timer: 0,
      id: null, //章节ID
      groupId: null, //章节组ID
      courseId: null, //录播ID
      classId: null,
      /* 音频 */
      ifplay: false,
      alltime: 60,
      thisTIme: 0,
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.id = this.$route.query.id;
    this.courseId = this.$route.query.courseId;
    this.classId =
      this.$route.query.classId && Number(this.$route.query.classId) > 0 ? this.$route.query.classId : null;
    this.groupId = this.$route.query.groupId;
    this.uservideoConfig = this.$route.query.uservideoConfig;
    await know.getPcAudioTeachDetail(this.courseId, this.classId ? this.classId : undefined).then((res) => {
      this.IsPurchase = res.msg.IsPurchase;
      this.courDetail = res.msg.audioTeach;
      this.$forceUpdate();
    });
    // 录播课程详情信息
    await know.getAudioLessonDetail(this.id).then((res) => {
      this.detail = res.msg;
    });

    await this.getLbClassCourse();
    setTimeout(() => {
      var x = document.getElementById("myAudio");
      this.alltime = x.duration;
    }, 1000);
  },
  methods: {
    async getLbClassCourse() {
      let userId = await localStorage.getItem("userId");
      getLbClassCourse(
        3,
        this.userInfo.id,
        this.userInfo.tenantId,
        this.classId ? this.classId : undefined,
        this.id,
        this.groupId,
        this.courseId,
        this.uservideoConfig
      );
    },
    // 课程概述
    nbvs(val) {
      this.NavIndex = val;
    },
    /* ------------音频------------------------ */
    play() {
      if (!this.IsPurchase) {
        return;
      }
      var x = document.getElementById("myAudio");
      x.play();
      this.alltime = x.duration;
      this.ifplay = true;
    },
    pause() {
      if (!this.IsPurchase) {
        return;
      }
      var x = document.getElementById("myAudio");
      x.pause();
      this.ifplay = false;
    },
    timeChange() {
      if (!this.IsPurchase) {
        return;
      }
      var myVid = document.getElementById("myAudio");
      // x.seek(this.thisTIme);
      // x.currentTime = this.thisTIme;
      myVid.load(); //方法重新加载音频/视频元素
      // https://www.w3school.com.cn/tags/av_prop_currenttime.asp
      // currentTime 属性设置或返回音频/视频播放的当前位置（以秒计）。
      // 当设置该属性时，播放会跳跃到指定的位置。
      myVid.currentTime = 20; //默认指定音频默认从20s的时候开始播放（默认时间为s）
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  // 顶部黑块
  .wrapTop {
    width: 100%;
    overflow: hidden;
    background: #222222;
    border-radius: 0px 0px 0px 0px;
    // 上方课程名
    .wrapTopsTop {
      width: 100%;
      height: 58px;
      line-height: 58px;
      background: #383838;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      padding: 18px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 左侧标题
      .wrapTopsTopL {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        // 图片

        // 文字标题
        p {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #aaaaaa;
          span {
            color: #ffffff;
          }
        }
      }
      // 右侧返回课程介绍
      .wrapTopsTopR {
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // 文字
        span {
          width: auto;
          height: auto;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        // 返回图片
        img {
          width: 13px;
          height: 11px;
          margin-right: 5px;
        }
      }
    }
    .videoBox {
      height: 553px;
      margin: 32px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      // 视频内容
      .wrapTopsCen {
        width: 880px;
        margin-right: 20px;

        // 中间视频
        .wrapTopsCenCen {
          width: 100%;
          height: 495px;
          background-size: 100% 100%;
          position: relative;
          .audio-Box {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 80px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 8px 8px 0px 0px;
            display: flex;
            padding: 0px 32px;
            align-items: center;
            .iconfont {
              font-size: 44px;
              color: #fff;
              cursor: pointer;
              flex-shrink: 0;
              margin-right: 24px;
            }
            .audio-content {
              padding: 12px 0px;
              height: 80px;
              .au-title {
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 16px;
                height: 16px;
              }
              .au-tips {
                height: 14px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 14px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .room {
    width: 320px;
  }
}
/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
/* 讲师 */
.detail-ter {
  flex-shrink: 0;
  .ter {
    color: #fff;
  }
}
.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;
}
/deep/.slider {
  width: 746px;
  .el-slider__bar {
    background: #ff5e51;
  }
  .el-slider__button {
    border-color: #ff5e51;
  }
}
</style>
